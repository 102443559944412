.container {
    background-color: var(--about-us-color);
    align-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    transition: 1s;
    padding: 3%;
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
}

.containerHeader {
    color: white;
    margin-top: 1rem;
    font-size: 2.4em;
    margin-bottom: 1rem;
    font-family: Raleway, sans-serif;
    font-weight: lighter;
    align-items: center;
}

.containerParagraph {
    display: flex;
    flex-direction: row;
    color: white;
    font-family: "Raleway", sans-serif;
    font-style: italic;
    font-size: large;
    font-weight: 200;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    width: 50%;
    padding-bottom: 0;
    transition: 1s;
}

.content {
    display: flex;
    justify-content: space-between;
}


@media only screen and (max-width: 768px) {
    .containerParagraph {
        width: 100%;
    }

    .container {
        padding: 5%;
    }
}