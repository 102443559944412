.footerContainer {
    display: flex;
    align-items: center;
    padding: 0 1%;
    background-color: #f5f5f5;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: .5rem .5rem 0 0;
}

.footerContainer p {
    margin: 1rem 2rem 1rem auto;
}

.themeButton {
    background-color: var(--background-color);
    border: none;
    cursor: pointer;
    outline: none;
    transition: 1s;
    color: white;
    padding: .5%;
    border-radius: .5rem;
}