.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: var(--events-color);
  width: 100%;
  margin: 0 auto;
  padding: 5% 5% 0 5%;
  transition: 1s;
  height: 100%;
  border-radius: .5rem;
}

.containerHeader {
  font-family: Raleway, sans-serif;
  font-size: 2.5rem;
  color: white;
  font-weight: lighter;
  transition: 1s;
  /* disable text selection */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.containerParagraph {
  font-family: Raleway, sans-serif;
  font-size: 1.2rem;
  color: white;
  font-weight: lighter;
  margin-top: 1rem;
  padding: 5%;
  /* disable text selection */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.eventContent {
  display: flex;
  flex-direction: row-reverse;
}

.eventScrollable {
  overflow: scroll;
  height: 12rem;
}

.upcomingEvents {
  background: white;
  border-radius: .5rem .5rem 0 0;
  width: 100%;
  padding: 4% 5% 0 1%;
}

.upcomingEventsHeader {
  font-family: Raleway, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--events-color);
  margin: 1rem;
}

.event {
  margin: 1rem;
  transition: 1s;
  border-left: 5px solid var(--events-color);
  padding: 2%;
  width: 90%;
  text-overflow: ellipsis;
}

.event:hover {
  background: var(--events-color);
  transition: 0.5s;
  width: 100%;
}

.event h3 {
  font-family: Raleway, serif;
  font-size: 1.5rem;
  color: var(--events-color);
  transition: 0.5s;
  overflow: inherit;
  white-space: nowrap;
  /* disable text selection */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.event:hover h3 {
  color: white;
  transition: 0.5s;
}

.event a {
  font-family: Raleway, serif;
  font-size: 1rem;
  background-color: var(--button-primary-color);
  color: var(--secondary-color);
  padding: 2%;
  font-weight: 400;
  transition: 0.5s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 0.5rem;
  align-content: center;
  line-height: 1.5em;
  margin: 0.16em;
  box-shadow: var(--events-color);
  box-sizing: content-box;
  /* disable text selection */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  text-decoration: none;
}

.event:hover a {
  color: var(--events-color);
  transition: 0.5s;
  padding: 2%;
  background-color: var(--secondary-color);
}

.event p {
  font-family: Raleway, serif;
  font-size: 1rem;
  color: var(--events-color);
  font-weight: 400;
  transition: 0.5s;
  white-space: pre-wrap;
  /* disable text selection */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.event:hover p {
  color: white;
  transition: 0.5s;
}

.eventLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10% 0 0;
  height: 20%;
}

.addToCalendarButton {
  padding: 5%;
  background-color: var(--button-primary-color);
  color: var(--secondary-color);
  --bs-btn-border-color: var(--button-secondary-color);
  --bs-btn-active-bg: var(--button-primary-color);
}

.addToCalendarButton:hover {
  color: var(--events-color);
  background-color: var(--secondary-color);
}

.addToCalendarMenu {
  width: 93%;
  background-color: transparent;
}

.addToCalendarItem:hover {
  color: var(--secondary-color) !important;
  background-color: var(--events-color) !important;
}

.lumaCalendar {
  width: 100%;
  min-height: 400px;
}

@media (max-width:1300px) {
  .eventContent {
    display: contents;
  }
  .event h3 {
    white-space: normal;
  }
  .eventScrollable {
    height: 14rem;
  }
}
