.container {
    background-color: var(--background-color);
    align-content: center;
    transition: 1s;
    margin-bottom: 1rem;
    padding: 2rem;
}

/* DESKTOP STYLES */
.spanContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headerMainText {
    color: white;
    font-weight: 100;
    font-family: Raleway, sans-serif;
    font-size: 1.5em;
    align-self: center;
}

.linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.link {
    color: white;
    font-weight: 100;
    font-family: Raleway, sans-serif;
    font-size: 1.5em;
    align-self: center;
    text-decoration: none;
    transition: 1s;
    margin-right: 1.5rem;
}

.link:hover {
    color: var(--link-hover-color); /* I really like this color */
    font-weight: 100;
    transition: 1s;
}

/* MOBILE STYLES */
.mobileLinkContainer {
    display: none;
    cursor: pointer;
}

.icon {
    color: white;
    font-size: 1.5em;
    font-weight: 100;
    padding: 0.5rem;
    transition: 1s;
    align-self: center;
    text-decoration: none;
}

.mobileLinks {
    display: flex;
    transition: 1s;
    flex-direction: column;
    align-items: right;
    transition-behavior: allow-discrete;
    transition: all 0.25s;
    opacity: 1;
    scale: 1;
}

.closed {
    display: none;
    opacity: 0;
    scale: 0;
    width: 0;
}

.mobileLinks .link {
    padding: 1rem;
    margin-right: 0;
    width: 100%;
    text-align: right;
    border-radius: 20px;
}

/* media query for anything bigger than 1300px */
@media (min-width: 1600px) {
    .container {
        width: 66%;
        transition: 1s;
        margin-left: 17%;
        margin-right: 17%;
    }
}

@media (max-width: 850px) {
    .linkContainer {
        display: none;
    }
    .mobileLinkContainer {
        display: flex;
    }
    .headerMainText {
        font-size: .8rem;
    }
}

@media (min-width: 851px) {
    .mobileLinks {
        display: none;
    }
}